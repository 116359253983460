import "client-only";

import { apiRequest } from "@sailes/api";
import { OnboardingTaskType } from "../../../conversation/api/models/onboarding-task-type";
import { ISession } from "../models/session";
import { CompanyRoleType, RoleType } from "../models/roles";
import { logger } from "@sailes/logging";
import { isNullOrUndefined } from "@sailes/utilities";

export interface AuthenticationDirectorContext {
    callbackUrl?: string;
    session?: ISession;
    app?: "portside" | "starboard";
}

export class AuthenticationDirector {
    async next(context: AuthenticationDirectorContext) {
        const { callbackUrl, session, app } = context;
        const role: RoleType = session?.user?.role as RoleType;
        const companyRole: CompanyRoleType = session?.user?.companyRole as CompanyRoleType;

        // if user has an internal role then exit early and return the callback url
        if ((RoleType.internal.includes(role) && isNullOrUndefined(companyRole)) || app === "portside") {
            logger.debug({
                domain: "packages/features/authentication/api/logic/authentication-director.ts",
                message: `User has an internal role, redirecting to callback url ${callbackUrl}`,
                userId: session?.user?.id,
            });

            return callbackUrl;
        }

        const hasCompletedFirstOpenTask = await apiRequest({
            url: `/api/onboarding/${OnboardingTaskType.firstOpen}`,
            method: "GET",
        });

        logger.debug({
            domain: "packages/features/authentication/api/logic/authentication-director.ts",
            message: `User has completed first open task: ${String(hasCompletedFirstOpenTask)}`,
            userId: session?.user?.id,
        });

        // check if the user has completed the onboarding task, if they have then return the callback url
        if (!hasCompletedFirstOpenTask) {
            logger.debug({
                domain: "packages/features/authentication/api/logic/authentication-director.ts",
                message: `Checking if the user should be allowed to go through the onboarding conversation.`,
                userId: session?.user?.id,
            });

            // check if the feature flag allowing the onboarding conversation and that the user's companyid is in the allowed list
            if (CompanyRoleType.value(companyRole) >= CompanyRoleType.value(CompanyRoleType.captain)) {
                logger.info({
                    domain: "packages/features/authentication/api/logic/authentication-director.ts",
                    message: "Redirecting to captain onboarding",
                    userId: session?.user?.id,
                });

                return "/onboarding/captain";
            }

            logger.info({
                domain: "packages/features/authentication/api/logic/authentication-director.ts",
                message: "Redirecting to sailer onboarding",
                userId: session?.user?.id,
            });

            return "/onboarding/sailer";
        }

        return callbackUrl;
    }
}
