export enum ApiErrorStatusCode {
    INVALID_INPUT = "INVALID_INPUT",
    UNAUTHORIZED_CRM_CONNECTION = "UNAUTHORIZED_CRM_CONNECTION",
    DUPLICATED_CRM_CONTENT = "DUPLICATED_CRM_CONTENT",
    BAD_REQUEST = "BAD_REQUEST",
    INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
    UNAUTHORIZED = "UNAUTHORIZED",
    UNHANDLED = "UNHANDLED",
    SAILEBOT_ALREADY_ASSIGNED_TO_USER = "SAILEBOT_ALREADY_ASSIGNED_TO_USER",
    USER_ALREADY_EXISTS = "USER_ALREADY_EXISTS",
    CLIENT_ALREADY_EXISTS = "CLIENT_ALREADY_EXISTS",
    FORBIDDEN = "FORBIDDEN",
}
