"use client";

import React from "react";
import clsx from "clsx";

export enum ActivityIndicatorColor {
    white = "text-white",
    black = "text-stone-900",
    red = "text-red-600",
    gray = "text-gray-200",
    darkGray = "text-stone-500",
}

export enum ActivityIndicatorSize {
    small = "SMALL",
    medium = "MEDIUM",
    large = "LARGE",
}

interface ActivityIndicatorProps {
    color?: ActivityIndicatorColor | typeof ActivityIndicatorColor[keyof typeof ActivityIndicatorColor] | string;
    size?: ActivityIndicatorSize | typeof ActivityIndicatorSize[keyof typeof ActivityIndicatorSize] | string;
}

export function ActivityIndicator(props: ActivityIndicatorProps) {
    const color = props.color ?? ActivityIndicatorColor.black;
    const size = props.size ?? ActivityIndicatorSize.small;

    return (
        <div
            role="status"
            className={clsx(
                "inline-block animate-spin rounded-full border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]",
                stylesForSize(size),
                color
            )}
        >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading</span>
        </div>
    );
}

function stylesForSize(size: ActivityIndicatorSize | typeof ActivityIndicatorSize[keyof typeof ActivityIndicatorSize] | string) {
    switch (size) {
        case ActivityIndicatorSize.small:
            return "h-4 w-4 border-2";
        case ActivityIndicatorSize.medium:
            return "h-6 w-6 border-2";
        case ActivityIndicatorSize.large:
            return "h-8 w-8 border-4";
    }
}
