import { ApiErrorStatusCode } from "./api-error-status-code";
import { ApiError } from "./api-error";

export class ApiResponseFactory {
    static createSuccessResponse(data?: any, message: string = "OK") {
        return new Response(data ? JSON.stringify(data) : null, { status: 200, statusText: message });
    }

    static createErrorResponse(code: ApiErrorStatusCode | string, message: string = "Error", status: number = 400) {
        return new Response(
            JSON.stringify({
                errors: [new ApiError(code, message).toObject()],
            }),
            {
                status: status || 400,
                headers: {
                    "content-type": "application/json",
                },
            }
        );
    }
}
